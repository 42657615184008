import { DataStore, API } from "aws-amplify";
import { getExoSolution } from "graphql/mutations";
import { WebsiteBenahmedEducation } from "../models";
import * as types from "./types";
import { ls, daysToMilliSecond } from "utility/helper";
import { v4 as uuid4 } from "uuid";

/**
 * get exercise solution
 * @param {string} PK
 * @param {string} exoId
 * @param {number} difficulty
 * @returns {Promise<{result:types.solution,errors:string}>}
 */
export const getSolution = async (PK, exoId, difficulty, userId) => {
  console.log("in get solution", ls(`${userId}:${exoId}`));
  if (PK != userId) {
    if (ls.has(`${userId}:${exoId}`)) {
      const localSolution = ls(`${userId}:${exoId}`);
      if (localSolution.type === "silverStars") {
        if (localSolution.date + daysToMilliSecond(3) >= Date.now()) {
          return {
            result: {
              solution: localSolution.value,
            },
            errors: null,
          };
        }
      }
      if (localSolution.type === "goldStars") {
        if (localSolution.date + daysToMilliSecond(300) >= Date.now()) {
          return {
            result: {
              solution: localSolution.value,
            },
            errors: null,
          };
        }
      }
    }
  }

  try {
    const solution = await API.graphql({
      query: getExoSolution,
      variables: { PK, SK: exoId, difficulty },
    });
    console.log(
      "🚀 ~ file: exercisesCRUD.js:19 ~ getSolution ~ solution:",
      solution
    );
    const {
      data: { getExoSolution: value },
    } = solution;

    if (value?.solution) {
      ls(`${userId}:${exoId}`, {
        date: Date.now(),
        type: value.unite,
        value: value.solution,
      });
    }

    return {
      result: {
        PK: value.GSI2_PK,
        SK: value.GSI2_SK,
        unite: value.unite,
        solution: value.solution,
        id: value.id,
      },
      errors: null,
    };
  } catch (e) {
    console.log("🚀 ~ file: exercisesCRUD.js:37 ~ getSolution ~ e:", e);

    const isOnline = navigator ? navigator.onLine : false;
    if (!isOnline) {
      return { errors: "لا يمكن رأيت الحل بدون أنترنت", result: null };
    }
    if (e.message === "No current user") {
      return {
        errors: "noUser#يجب عليك أن تسجل حساب ليظهر الحل",
        result: null,
      };
    }
    const { errors } = e;
    return { errors: errors?.[0].message, result: null };
  }
};

export const updateSolution = async value => {
  const { PK, SK, solution } = value;

  const [original] = await DataStore.query(WebsiteBenahmedEducation, w =>
    w.and(w => [w.PK.eq(PK), w.SK.eq(SK)])
  );
  console.log("in update solution", original, solution);
  return await DataStore.save(
    WebsiteBenahmedEducation.copyOf(original, updated => {
      if (solution) {
        updated.solution = solution;
      }
    })
  );
};
export const setSolution = async value => {
  console.log("in set solution", value);
  if (value.id) {
    const { id } = await updateSolution(value);
    return id;
  } else {
    const { id } = await DataStore.save(
      new WebsiteBenahmedEducation({ ...value, id: uuid4() })
    );
    return id;
  }
};
