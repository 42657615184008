/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_appsync_graphqlEndpoint": "https://mpjvfw6fljhhvntre3woa3vsuy.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-west-1:a44cb061-d767-464a-b6df-bb669c2948aa",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_fXUtsjRrw",
    "aws_user_pools_web_client_id": "2cihsv5irdlue5l5q96lsgkclo",
    "oauth": {
        "domain": "benahmededucationdf0973a9-df0973a9-staging.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://benahmed.education/App/thankyou/,http://localhost:8000/App/thankyou/,http://localhost:9000/App/thankyou/",
        "redirectSignOut": "https://benahmed.education/,http://localhost:8000/,http://localhost:9000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "benahmededucationa2299fc4f2564b3ca93899d110eb18162056-staging",
    "aws_user_files_s3_bucket_region": "eu-west-1"
};


export default awsmobile;
